<template>
	<div>
		<Nav/>
		<Banner/>
		<div class="container">
			<el-breadcrumb separator="/" class="top">
				<el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
				<el-breadcrumb-item>公司產品</el-breadcrumb-item>
			</el-breadcrumb>
			<!--<el-card class="top">-->
			<!--	<div class="left">-->
			<!--		<img class="top5" style="width: 150px; height: 150px;border: 1px solid #e1e1e1" src="../../assets/images/weChat-1.jpg"/>-->
			<!--	</div>-->
			<!--</el-card>-->
			<div class="introduce">
				<h1 class="center">《魂之守護》</h1>
				<p class="center" style="width: 40%;margin: 30px auto;line-height: 35px;">全新克蘇魯策略卡牌手遊。《魂之守護》是壹款輕克蘇魯幻想的泛二次元策略放置手遊，妳將化身指揮官，集結覺醒者們發起對教會的反抗戰爭。</p>
			</div>
			<div class="introduce" style="padding-bottom: 30px;padding-top: 60px;">
				<h1 class="center">《戰火英雄》</h1>
				<p class="center" style="width: 40%;margin: 30px auto;line-height: 35px;">《戰火英雄》是壹款3D末日科幻風的多人生存類ARPG手遊，末日廢土，英雄掘起！在遊戲中玩家可以變身神槍手或者遊俠，在喪屍變異巨獸肆虐的廢土上求生，在戰鬥中不斷使自己變強。更能組隊升級闖副本，在蟲族變異、巨獸肆虐的廢土拯救世界！ 【末日科幻，廢土求生】 玩家不僅能夠打造不同風格的人物，從壹個剛入伍的新兵開始，擊殺各種異形、怪獸，探索各種未知的大陸，成就屬于自己的傳奇故事。廢土之下，只有不斷變強，才能突破重圍！ 【高清畫面，酷炫3D】 槍林彈雨不停歇，讓玩家身臨其境，體驗酣暢淋漓的射擊快感！。《魂之守護》是壹款輕克蘇魯幻想的泛二次元策略放置手遊，妳將化身指揮官，集結覺醒者們發起對教會的反抗戰爭。</p>
			</div>
		</div>
		<Footer class="top"/>
	</div>
</template>

<script>
import Banner from './components/Banner'

export default {
    name: 'Home',
    components: {Banner},
    props: {},
    watch: {},
    data() {
        return {};
    },

    mounted() {

    },

    created() {
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
.container{
  .introduce{
	min-height: 200px;
	padding-top: 80px;
  }
}

</style>
